
.fruitbasket-block {
    margin: 15px;
    padding: 20px;

    border-radius: 20pt;
    border-style: none;

    outline-color: black;
    outline-style: solid;
    outline-width: 3pt;
}

.fruitbasket-block h2 {
    color: var(--main-text-color);
    
    margin: 0px;
    margin-bottom: 8px;
    
    padding: 0px;
    
    font-size: 28px;
    
    font-weight: bold;
}

.fruitbasket-block p {
    color: #ffffffdd;
    
    margin: 0px;
    margin-top: 4px;
    padding: 0px;
    
    font-weight: 400;
}


.fruitbasket-lower {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.contact {
    flex: 1;
}

.cool-people {
    flex: 1;
}

.link-grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.link-grid-item {
    width: 25%;
    height: 0;
    
    max-width: 25%;
    
    padding-bottom: 25%;
}

.link-grid-img-container {
    position: relative;
    
    width: calc(100% - 8px);
    height: 0;
    
    padding-bottom: calc(100% - 8px);
}

.link-grid-img {
    position: absolute;
    
    top: 4px;
    left: 4px;
    
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    border-radius: 20pt;
    border-style: none;

    outline-color: black;
    outline-style: solid;
    outline-width: 3pt;
}

.link-grid-item-cover {
    position: absolute;
    
    top: 4px;
    left: 4px;
    width: 100%;
    height: 100%;
    
    border-radius: 20pt;
    border-style: none;

    outline-color: black;
    outline-style: solid;
    
    background-color: #ffffff;
    
    opacity: 0%;
    
    transition: opacity 0.2s;
}

.link-grid-item-cover:hover {
    opacity: 40%;
    
}

@media only screen and (max-width: 768px) {
    
    .fruitbasket-lower {
        flex-direction: column;
    }
}

